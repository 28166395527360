import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {ConfigService} from '../../../services/config.service';
import {first} from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import {AccountSelectorPopupComponent} from "../../../dashboard/template/account-selector-popup/account-selector-popup.component";
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-loginIP',
  templateUrl: './loginIP.component.html',
  styleUrls: ['./loginIP.component.css']
})
export class LoginIPComponent implements OnInit {
  protected aFormGroup: FormGroup;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  // tslint:disable-next-line:variable-name
  error_logo = false;
  returnUrl: string;
  constructor(public translate: TranslateService,
              public pageTitle: Title,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private loginService: LoginService,
              private config: ConfigService,
              private spinner: NgxSpinnerService,
              private dialog: MatDialog) {
    translate.addLangs(['fr', 'en']);
    translate.setDefaultLang('fr');
    pageTitle.setTitle('Bienvenue sur la plateforme CertiKap');

    if (this.loginService.currentUserValue) {
      this.router.navigate(['/dashboard/inspector']);
    } else {
      this.activatedRoute.params.subscribe(params => {
        if (params.hasOwnProperty('id') && params.hasOwnProperty('token')) {
          if (params.id != '' && params.token != '') {
            this.spinner.show();
            this.loading = true;
            this.loginService.loginWithToken(params.id, params.token)
              .pipe(first())
              .subscribe(
                res => {
                  this.spinner.hide();
                  if(res.status === 'multicomptes'){
                    this.openMulticomptesDialog(res.multicomptes);
                  }
                  else if (res.status === 'success') {
                    localStorage.setItem('profile_id', res.profile_id);
                    localStorage.setItem('site_id', res.site_id);
                    if (res.profile_id === 10 || res.profile_id === 8) {
                      window.location.reload();
                      this.router.navigate(['dashboard/inspector']);
                      return false;
                    }
                    window.location.reload();
                    this.router.navigate(['dashboard/administrator']);
                    return false;
                  }
                },
                error => {
                  this.spinner.hide();
                  this.error_logo = true;
                  this.loading = false;
                });
          }
        }
      });
    }

  }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/dashboard/administrator']);
    }
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard/inspector';
  }
  switchLang(lang: string) {this.translate.use(lang); }
  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;
    this.spinner.show();

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.spinner.hide();
      return;
    }

    this.loading = true;
    this.loginService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        res => {
          this.spinner.hide();
          this.error_logo = false;
          if(res.status === 'multicomptes'){
            this.openMulticomptesDialog(res.multicomptes);
          }
          else if (res.status === 'success') {
              localStorage.setItem('profile_id', res.profile_id);
              localStorage.setItem('site_id', res.site_id);
              if (res.profile_id === 10 || res.profile_id === 8) {
                window.location.reload();
                this.router.navigate(['dashboard/inspector']);
                return false;
              }
              window.location.reload();
              this.router.navigate(['dashboard/administrator']);
              return false;
          }
        }, (err) => {
          this.spinner.hide();
          this.error_logo = true;
          this.loading = false;
        });

  }

  // tslint:disable-next-line:variable-name
  redirectToAdmin(profile_id)  {
    switch (profile_id) {
      case 10: {
        this.router.navigate(['dashboard/inspector']);
        break;
      }
      case 9: {
        this.router.navigate(['dashboard/administrator']);
        break;
      }

    }
  }

  // Méthode pour ouvrir la pop-up
  openMulticomptesDialog(multicomptes: any[]) {
    const dialogRef = this.dialog.open(AccountSelectorPopupComponent, {
      width: '600px',
      height: 'auto',  // Hauteur automatique en fonction du contenus
      data: { multicomptes: multicomptes }
    });

    dialogRef.afterClosed().subscribe(selectedCompte => {
      if (selectedCompte) {
        // Si un compte a été sélectionné, tu peux gérer la redirection ici
        this.switchAccount(selectedCompte);
      }
    });
  }

  switchAccount(User :any){
    this.spinner.show();
    this.loginService.switchAccount(User.login)
      .subscribe(res => {
        if (res.status == 'success') {
          this.spinner.hide();
          localStorage.setItem('profile_id', res.profile_id);
          localStorage.setItem('site_id', res.site_id);
          if (res.profile_id === 10 || res.profile_id === 8) {
            window.location.reload();
            this.router.navigate(['dashboard/inspector']);
            return false;
          }
          window.location.reload();
          this.router.navigate(['dashboard/administrator']);
          return false;
        }
      }, (err) => {
        this.spinner.hide();
        console.log(err);
      });
  }

}
