/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-selector-popup.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/common";
import * as i7 from "./account-selector-popup.component";
import * as i8 from "@angular/material/dialog";
var styles_AccountSelectorPopupComponent = [i0.styles];
var RenderType_AccountSelectorPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountSelectorPopupComponent, data: {} });
export { RenderType_AccountSelectorPopupComponent as RenderType_AccountSelectorPopupComponent };
function View_AccountSelectorPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary w-100"], ["mat-button", ""], ["tabindex", "-1"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelectAccount(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(3, 0, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.profile_name; _ck(_v, 3, 0, currVal_2); }); }
export function View_AccountSelectorPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["S\u00E9lectionner le profil"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectorPopupComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.multicomptes; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AccountSelectorPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-selector-popup", [], null, null, null, View_AccountSelectorPopupComponent_0, RenderType_AccountSelectorPopupComponent)), i1.ɵdid(1, 49152, null, 0, i7.AccountSelectorPopupComponent, [i8.MatDialogRef, i8.MAT_DIALOG_DATA], null, null)], null, null); }
var AccountSelectorPopupComponentNgFactory = i1.ɵccf("app-account-selector-popup", i7.AccountSelectorPopupComponent, View_AccountSelectorPopupComponent_Host_0, { showPopup: "showPopup", accounts: "accounts" }, {}, []);
export { AccountSelectorPopupComponentNgFactory as AccountSelectorPopupComponentNgFactory };
